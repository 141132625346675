import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  identityApiRef,
  analyticsApiRef,
  createApiFactory,
} from '@backstage/core-plugin-api';

// Matomo analytics
import { MatomoAnalytics } from '@backstage-community/plugin-analytics-module-matomo';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),

  // Matomo analytics
  createApiFactory({
    api: analyticsApiRef,
    deps: { configApi: configApiRef, identityApi: identityApiRef },
    factory: ({ configApi, identityApi }) =>
      MatomoAnalytics.fromConfig(configApi, {
        identityApi,
      }),
  }),
];
